import React from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import Layout from '../components/Layout'
import Footer from '../components/footer'
import facebooklogo from '../assets/images/icon/facebook.svg'
import linkedinlogo from '../assets/images/icon/linkedin.svg'
import knsicongrey from '../assets/images/icon/kns-icon-grey.svg'
import knsblue from '../assets/images/logo/kns-blue.svg'
import multi from '../assets/images/logo/multi.png'
import cefic from '../assets/images/logo/cefic.png'
import emaar from '../assets/images/logo/emaar.png'
import ronesanslogo from '../assets/images/logo/ronesans.png'
import ronesans from '../assets/images/logo/ronesans-logo.png'
import enkalogo from '../assets/images/logo/enka.png'
import multilogo from '../assets/images/logo/multi-logo.png'
import ceficlogo from '../assets/images/logo/cefic-logo.png'
import atakulegyo from '../assets/images/logo/atakule-gyo.png'
import ece from '../assets/images/logo/ece.png'
import gulaylar from '../assets/images/logo/gulaylar.png'
import gulaylarlogo from '../assets/images/logo/gulaylar-logo.png'
import jll from '../assets/images/logo/jll.png'
import klepierre from '../assets/images/logo/klepierre.png'
import klepierrelogo from '../assets/images/logo/klepierre-logo.png'
import metro from '../assets/images/logo/metro.png'
import pd from '../assets/images/logo/pd.png'
import $ from 'jquery'
import LocalizedLink from '../LocalizedLink'

class ClientsPage extends React.Component {
  componentDidMount() {
    if (window.location.pathname === '/clientsPage') {
      $('.logo a img').attr('src', knsblue)
      $('.menu ul li a').css('color', '#1b437b')
      $('.header').css('background', '#ffffff')
      $('.language').css('border-color', '#1b437b')
      $('.language a span').css('color', '#1b437b')
      $('.other-page-social').show(100)
      $('#nav-toggle span').addClass('colorline')
      $('.numbers h1,.numbers h4').css('color', '#1b437b')
    }

    $('.mb-nav-btn').click(function() {
      $('.mobile-menu').fadeIn(200)
    })
    $('.close').click(function() {
      $('.mobile-menu').fadeOut(200)
    })

    $('.mobile-menu ul li a').click(function() {
      $('.mobile-menu').fadeOut(200)
    })
  }
  render() {
    return (
      <Layout locale={this.props.pageContext.locale}>
        <body className="other-page">
          <header className="header">
            <div className="row">
              <div className="col-3 logo">
                <a href="/">
                  <img
                    src={require('../assets/images/logo/kns.svg')}
                    alt="KNS"
                    title="KNS"
                  />
                </a>
              </div>
              <div className="col-9">
                {/*<div href="#" className="language">*/}

                {/*{this.props.pageContext.locale === "en" ? this.state.en : this.state.tr}*/}

                {/*</div>*/}
                <a href="#" id="nav-toggle" class="mb-nav-btn">
                  <span />
                </a>
                <nav className="menu">
                  <ul>
                    <li>
                      <LocalizedLink to="/aboutPage">
                        <FormattedMessage id="about" />
                      </LocalizedLink>
                    </li>
                    <li>
                      <LocalizedLink to="/platformsPage">
                        <FormattedMessage id="platforms" />
                      </LocalizedLink>
                    </li>
                    <li>
                      <LocalizedLink to="/iys">
                        <FormattedMessage id="İYS" />
                      </LocalizedLink>
                    </li>
                    <li>
                      <LocalizedLink to="/supportPage">
                        <FormattedMessage id="supports" />
                      </LocalizedLink>
                    </li>
                    <li>
                      <LocalizedLink to="/clientsPage">
                        <FormattedMessage id="clients" />
                      </LocalizedLink>
                    </li>
                    <li>
                      <LocalizedLink to="/platforms/venuex">
                        <FormattedMessage id="Venuex" />
                      </LocalizedLink>
                    </li>
                    <li>
                      <LocalizedLink to="/careerPage">
                        <FormattedMessage id="Career" />
                      </LocalizedLink>
                    </li>
                    <li>
                      <LocalizedLink to="/contactPage">
                        <FormattedMessage id="Contact" />
                      </LocalizedLink>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </header>

          <div className="mobile-menu">
            <div className="close">
              <img src={require('../assets/images/icon/close-nav.svg')} />
            </div>

            <nav className="mb-nav">
              <ul>
                <li>
                  <LocalizedLink to="/aboutPage">
                    <FormattedMessage id="about" />
                  </LocalizedLink>
                </li>
                <li>
                  <LocalizedLink to="/platformsPage">
                    <FormattedMessage id="platforms" />
                  </LocalizedLink>
                </li>
                <li>
                      <LocalizedLink to="/iys">
                        <FormattedMessage id="İYS" />
                      </LocalizedLink>
                    </li>
                <li>
                  <LocalizedLink to="/supportPage">
                    <FormattedMessage id="supports" />
                  </LocalizedLink>
                </li>
                <li>
                  <LocalizedLink to="/clientsPage">
                    <FormattedMessage id="clients" />
                  </LocalizedLink>
                </li>
                <li>
                  <LocalizedLink to="/platforms/venuex">
                    <FormattedMessage id="Venuex" />
                  </LocalizedLink>
                </li>
                <li>
                  <LocalizedLink to="/careerPage">
                    <FormattedMessage id="Career" />
                  </LocalizedLink>
                </li>
                <li>
                  <LocalizedLink to="/contactPage">
                    <FormattedMessage id="Contact" />
                  </LocalizedLink>
                </li>
              </ul>
            </nav>
          </div>

          <article className="home-social">
            <h2>
              <FormattedMessage id="follow" />
            </h2>
            <figure className="blue-line" />
            <a
              href="https://www.facebook.com/knsbilisim/"
              className="fb"
              target="_blank"
            >
              <img src={facebooklogo} />
            </a>
            <a
              href="https://www.linkedin.com/company/kns-information-technologies/"
              className="linkedin"
              target="_blank"
            >
              <img src={linkedinlogo} />
            </a>
          </article>
          <section className="page">
            <div className="container">
              <div className="col-12">
                <figure className="kns-grey-icon">
                  <img src={knsicongrey} />
                </figure>

                <div className="page-prp">
                  <h1>
                    <FormattedMessage id="clients" />
                  </h1>
                  {this.props.pageContext.locale == 'tr' && (
                    <>
                      <p>
                        Create a remarkable visitor experience with the touch of
                        a sustainable digital marketing platform.
                      </p>
                    </>
                  )}
                  {this.props.pageContext.locale == 'en' && (
                    <>
                      <p>
                        Create a remarkable visitor experience with the touch of
                        a sustainable digital marketing platform.
                      </p>
                    </>
                  )}
                </div>

                <div className="container">
                  <figure className="about-image">
                    <div className="about-prp">
                      <aside className="numbers">
                        <h1>8+</h1>
                        <h4>
                          <FormattedMessage id="years" />
                        </h4>
                      </aside>
                      <aside className="numbers">
                        <h1>7</h1>
                        <h4>
                          <FormattedMessage id="countries" />
                        </h4>
                      </aside>
                      <aside className="numbers">
                        <h1>150+</h1>
                        <h4>
                          <FormattedMessage id="customers" />
                        </h4>
                      </aside>
                    </div>
                  </figure>
                </div>

                <section className="clients">
                  <div className="container">
                    <div className="customer-logos text-center">
                      <img src={atakulegyo} />
                      <img src={cefic} />
                      <img src={ece} />
                      <img src={emaar} />
                      <img src={enkalogo} />
                      <img src={gulaylar} />
                      <img src={jll} />
                      <img src={klepierre} />
                      <img src={metro} />
                      <img src={multi} />
                      <img src={pd} />
                      <img src={ronesanslogo} />
                    </div>
                  </div>
                </section>

                <section className="testimonials text-center">
                  <div className="container">
                    <h3>Testimonials</h3>

                    <article className="tm-item row">
                      <div className="col-12 col-sm-12 col-md-3 col-lg-2">
                        <div className="tm-logo">
                          <img src={multilogo} />
                        </div>
                      </div>
                      <div className="col-12 col-sm-12 col-md-9 col-lg-10 text-left">
                        {this.props.pageContext.locale == 'tr' && (
                          <>
                            <p>
                              KNS proved to be an important partner for the
                              creation of our network of outlet centers in
                              Italy. Our 5 outlets share an integrated platform
                              which allowed us to promote the brand Land of
                              Fashion to the digital visitors of our websites as
                              well as to the physical customers thanks to the
                              proximity devices we installed and our APP.
                            </p>
                            <h4>Luca Zaccomer</h4>
                            <h5>Marketing Director, Multi Corporation</h5>
                          </>
                        )}
                        {this.props.pageContext.locale == 'en' && (
                          <>
                            <p>
                              KNS proved to be an important partner for the
                              creation of our network of outlet centers in
                              Italy. Our 5 outlets share an integrated platform
                              which allowed us to promote the brand Land of
                              Fashion to the digital visitors of our websites as
                              well as to the physical customers thanks to the
                              proximity devices we installed and our APP.
                            </p>
                            <h4>Luca Zaccomer</h4>
                            <h5>Marketing Director, Multi Corporation</h5>
                          </>
                        )}
                      </div>
                    </article>

                    <article className="tm-item row">
                      <div className="col-12 col-sm-12 col-md-3 col-lg-2">
                        <div className="tm-logo">
                          <img src={ronesans} />
                        </div>
                      </div>
                      <div className="col-12 col-sm-12 col-md-9 col-lg-10 text-left">
                        {this.props.pageContext.locale == 'tr' && (
                          <>
                            <p>
                              KNS has been one of our greatest supporters as a
                              company that understands its client’s exact needs
                              and offers tailor-made solutions. Allowing access
                              from one platform to all digital channels; it is
                              making the process easy to follow for visitors,
                              tenants and the shopping mall management. As
                              Rönesans, we are working with KNS in our shopping
                              malls and we are highly content with the
                              efficiency of digital marketing solutions which
                              are the latest way of reaching our visitors. We
                              believe that we have a sustainable business
                              partnership with KNS who always presents
                              up-to-date solutions appropriate to the situation
                              and to the time period we are in.
                            </p>
                            <h4>Meryem Özkan</h4>
                            <h5>Marketing Manager, Rönesans Real Estate</h5>
                          </>
                        )}
                        {this.props.pageContext.locale == 'en' && (
                          <>
                            <p>
                              KNS has been one of our greatest supporters as a
                              company that understands its client’s exact needs
                              and offers tailor-made solutions. Allowing access
                              from one platform to all digital channels; it is
                              making the process easy to follow for visitors,
                              tenants and the shopping mall management. As
                              Rönesans, we are working with KNS in our shopping
                              malls and we are highly content with the
                              efficiency of digital marketing solutions which
                              are the latest way of reaching our visitors. We
                              believe that we have a sustainable business
                              partnership with KNS who always presents
                              up-to-date solutions appropriate to the situation
                              and to the time period we are in.
                            </p>
                            <h4>Meryem Özkan</h4>
                            <h5>Marketing Manager, Rönesans Real Estate</h5>
                          </>
                        )}
                      </div>
                    </article>

                    <article className="tm-item row">
                      <div className="col-12 col-sm-12 col-md-3 col-lg-2">
                        <div className="tm-logo">
                          <img src={ceficlogo} />
                        </div>
                      </div>
                      <div className="col-12 col-sm-12 col-md-9 col-lg-10 text-left">
                        {this.props.pageContext.locale == 'tr' && (
                          <>
                            <p>
                              We have been working with KNS for our digital
                              marketing needs for many years. KNS keeps up with
                              the latest technology, improves itself in its
                              sector and offers diverse alternatives to its
                              clients. The fact that it follows the technology
                              so closely has made KNS a good business partner
                              for us which offers creative solutions. With its
                              young, dynamic and always accessible team KNS is
                              one of the best business partners a corporation
                              could have… As Cefic, we are certain that our
                              long-lasting business relationship will continue
                              with the same success and stability.
                            </p>
                            <h4>Alev Çalık Olgaç</h4>
                            <h5>
                              Corporate Communications & Marketing Director,
                              Cefic Turkey
                            </h5>
                          </>
                        )}
                        {this.props.pageContext.locale == 'en' && (
                          <>
                            <p>
                              We have been working with KNS for our digital
                              marketing needs for many years. KNS keeps up with
                              the latest technology, improves itself in its
                              sector and offers diverse alternatives to its
                              clients. The fact that it follows the technology
                              so closely has made KNS a good business partner
                              for us which offers creative solutions. With its
                              young, dynamic and always accessible team KNS is
                              one of the best business partners a corporation
                              could have… As Cefic, we are certain that our
                              long-lasting business relationship will continue
                              with the same success and stability.
                            </p>
                            <h4>Alev Çalık Olgaç</h4>
                            <h5>
                              Corporate Communications & Marketing Director,
                              Cefic Turkey
                            </h5>
                          </>
                        )}
                      </div>
                    </article>

                    <article className="tm-item row">
                      <div className="col-12 col-sm-12 col-md-3 col-lg-2">
                        <div className="tm-logo">
                          <img src={multilogo} />
                        </div>
                      </div>
                      <div className="col-12 col-sm-12 col-md-9 col-lg-10 text-left">
                        {this.props.pageContext.locale == 'tr' && (
                          <>
                            <p>
                              We purchased the digital signages placed in our
                              mall from KNS. Since it is an İstanbul based
                              company, at first we had some concerns about
                              communication in case of a technical problem and
                              during the content management process, yet KNS
                              reassured us in the course of the process. We can
                              reach their team about displaying new content and
                              technical support while we have the pleasure of
                              obtaining quick solutions.
                            </p>
                            <h4>Neslihan Karaoğlu</h4>
                            <h5>Marketing Manager, Multi Corporation</h5>
                          </>
                        )}
                        {this.props.pageContext.locale == 'en' && (
                          <>
                            <p>
                              We purchased the digital signages placed in our
                              mall from KNS. Since it is an İstanbul based
                              company, at first we had some concerns about
                              communication in case of a technical problem and
                              during the content management process, yet KNS
                              reassured us in the course of the process. We can
                              reach their team about displaying new content and
                              technical support while we have the pleasure of
                              obtaining quick solutions.
                            </p>
                            <h4>Neslihan Karaoğlu</h4>
                            <h5>Marketing Manager, Multi Corporation</h5>
                          </>
                        )}
                      </div>
                    </article>

                    <article className="tm-item row">
                      <div className="col-12 col-sm-12 col-md-3 col-lg-2">
                        <div className="tm-logo">
                          <img src={klepierrelogo} />
                        </div>
                      </div>
                      <div className="col-12 col-sm-12 col-md-9 col-lg-10 text-left">
                        {this.props.pageContext.locale == 'tr' && (
                          <>
                            <p>
                              As Klépierre Turkey, we have been cooperating with
                              KNS in the development and content management of
                              digital directories, websites and mobile
                              applications of all our shopping malls. CRM
                              project conducted by our headquarters in France in
                              all Klépierre countries, can only be carried out
                              with KNS in Turkey. We are racing against time
                              together in a period when systems for
                              understanding customer behavior, turning data into
                              marketing strategies, online platforms and digital
                              world are gaining more importance with each
                              passing day. We are enjoying the advantage of
                              deriving our motivation from our compatible
                              relationship with KNS. We are achieving
                              outstanding work together with our business
                              partnership and long-lasting friendship as
                              solution partners in every area! I hope that we
                              have a long road before us on the basis of mutual
                              trust with KNS who always stands by us working
                              hard to give us the best solutions.
                            </p>
                            <h4>Gözde Tümay</h4>
                            <h5>Marketing Manager, Klépierre Turkey</h5>
                          </>
                        )}
                        {this.props.pageContext.locale == 'en' && (
                          <>
                            <p>
                              As Klépierre Turkey, we have been cooperating with
                              KNS in the development and content management of
                              digital directories, websites and mobile
                              applications of all our shopping malls. CRM
                              project conducted by our headquarters in France in
                              all Klépierre countries, can only be carried out
                              with KNS in Turkey. We are racing against time
                              together in a period when systems for
                              understanding customer behavior, turning data into
                              marketing strategies, online platforms and digital
                              world are gaining more importance with each
                              passing day. We are enjoying the advantage of
                              deriving our motivation from our compatible
                              relationship with KNS. We are achieving
                              outstanding work together with our business
                              partnership and long-lasting friendship as
                              solution partners in every area! I hope that we
                              have a long road before us on the basis of mutual
                              trust with KNS who always stands by us working
                              hard to give us the best solutions.
                            </p>
                            <h4>Gözde Tümay</h4>
                            <h5>Marketing Manager, Klépierre Turkey</h5>
                          </>
                        )}
                      </div>
                    </article>

                    <article className="tm-item row">
                      <div className="col-12 col-sm-12 col-md-3 col-lg-2">
                        <div className="tm-logo">
                          <img src={gulaylarlogo} />
                        </div>
                      </div>
                      <div className="col-12 col-sm-12 col-md-9 col-lg-10 text-left">
                        {this.props.pageContext.locale == 'tr' && (
                          <>
                            <p>
                              In today’s world where virtual communication is
                              more common and accessible than one-to-one
                              contact, digital directories that are newly
                              integrated to the retail world are starting a new
                              era in terms of providing fast communication and
                              uninterrupted service to the customers. Websites,
                              Digital Kiosks, and Mobile Applications are the
                              leading digital factors that have a great role in
                              shaping the brand identity of a shopping mall, in
                              addition to their communicational purposes. KNS
                              exhibiting its difference with the inclusive
                              service it gives in those fields, continues to
                              produce work in international standards for our
                              cooperative businesses. I wish that the visual
                              integrity of the work done by their young and
                              dynamic team, their up-to-date technological
                              infrastructure and unconditional customer
                              satisfaction will make KNS the leader of the
                              sector.
                            </p>
                            <h4>Merve Gülay</h4>
                            <h5>
                              Project and Brand Manager, Kozyatağı Carrefour
                              Shopping Mall
                            </h5>
                          </>
                        )}
                        {this.props.pageContext.locale == 'en' && (
                          <>
                            <p>
                              In today’s world where virtual communication is
                              more common and accessible than one-to-one
                              contact, digital directories that are newly
                              integrated to the retail world are starting a new
                              era in terms of providing fast communication and
                              uninterrupted service to the customers. Websites,
                              Digital Kiosks, and Mobile Applications are the
                              leading digital factors that have a great role in
                              shaping the brand identity of a shopping mall, in
                              addition to their communicational purposes. KNS
                              exhibiting its difference with the inclusive
                              service it gives in those fields, continues to
                              produce work in international standards for our
                              cooperative businesses. I wish that the visual
                              integrity of the work done by their young and
                              dynamic team, their up-to-date technological
                              infrastructure and unconditional customer
                              satisfaction will make KNS the leader of the
                              sector.
                            </p>
                            <h4>Merve Gülay</h4>
                            <h5>
                              Project and Brand Manager, Kozyatağı Carrefour
                              Shopping Mall
                            </h5>
                          </>
                        )}
                      </div>
                    </article>
                  </div>
                </section>
              </div>
            </div>
          </section>
          <Footer />
        </body>
      </Layout>
    )
  }
}
ClientsPage.propTypes = {
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
  }).isRequired,
}
export default ClientsPage
